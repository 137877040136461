import React from 'react';
import './Contato.css';
import Email from "../../assets/email.webp";
import Wpp from "../../assets/wpp.png";
import Instagram from "../../assets/instagram.webp";
import Doctoralia from "../../assets/doctoralia.webp";

const Contato = () => {
    return (
        <section className="contato" id="contato">
            <div className="contato-titulo">
                <h2>Contato</h2>
            </div>
            <div className="contato-area">
                <a href="https://api.whatsapp.com/send?phone=5521995326778&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais" target="_blank" rel="noopener noreferrer" className="contato-card" aria-label="Link para o Whatsapp">
                    <img src={Wpp} alt="Whatsapp Icon" />
                    <h3>Whatsapp</h3>
                    <p>(21) 99532-6778</p>
                </a>

                <a href="https://www.instagram.com/daltro.genetica" target="_blank" rel="noopener noreferrer" className="contato-card" aria-label="Link para o Instagram">
                    <img src={Instagram} alt="Instagram Icon" />
                    <h3>Instagram</h3>
                    <p>@daltro.genetica</p>
                </a>

                <a href="https://www.doctoralia.com.br/daltro-castelar/geneticista-medico-clinico-geral/rio-de-janeiro" target="_blank" rel="noopener noreferrer" className="contato-card" aria-label="Link para o Doctoralia">
                    <img src={Doctoralia} alt="Doctoralia Icon" />
                    <h3>Doctoralia</h3>
                    <p>Dr Daltro Castelar</p>
                </a>

                <a href="mailto:daltro.castelar.jr@gmail.com" target="_blank" rel="noopener noreferrer" className="contato-card" aria-label="Link para o Email">
                    <img src={Email} alt="Email Icon" className='email' />
                    <h3>Email</h3>
                    <p>daltro.castelar.jr@gmail.com</p>
                </a>
            </div>
        </section>
    );
};

export default Contato;
