import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from "../../assets/logo.webp";
import WhatsAppIcon from "../../assets/whatsapp-64.ico";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1196);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1196);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className='header-container'>
            <div className='nav-image'>
                    <a href="/#top"><img src={Logo} alt="Logo" className="logo-img" /></a>
                </div>
                <div className="menu-icon" onClick={toggleMenu}>
                    <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
                    <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
                    <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
                </div>
                <nav
                    className={`nav-menu ${isOpen ? 'open' : ''}`}
                    {...(!isMobile ? { "data-aos": "fade-down", "data-aos-easing": "ease-out-cubic", "data-aos-duration": "2000" } : {})}
                >
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#sobre">Sobre</a></li>
                        <li><a href="#terapia">Aconselhamento Genético</a></li>
                        <li><a href="#atuacao">Atuação</a></li>
                        <li><a href="#contato">Contato</a></li>
                        <li><a href="#atendimento">Atendimento</a></li>
                    </ul>
                </nav>
                
                <a className="whatsapp-link" href="https://api.whatsapp.com/send?phone=5521995326778" target="_blank" rel="noopener noreferrer">
                    <button className={`whatsapp-button ${isMobile ? 'mobile' : ''}`}>
                        <img  id="header-img"src={WhatsAppIcon} alt="WhatsApp" className={`whatsapp-icon ${isMobile ? 'mobile' : ''}`} />
                        {!isMobile && <span>Whatsapp</span>}
                    </button>
                </a>
        </header>
    );
}

export default Header;
