import React from "react";
import "./Whatsapp.css";
import whatsappIcon from "../../assets/whatsapp-64.ico";

const Whatsapp = () => {



    const mensagem = "Olá! Estou interessado em saber mais. Poderia me ajudar?";
    
    const urlWhatsapp = `https://api.whatsapp.com/send?phone=5521995326778&text=${encodeURIComponent(mensagem)}`;

    return (
        <>  
            <a
                href={urlWhatsapp}
                className="float"
                target="_blank"
                rel="noreferrer"
            >
                <img src={whatsappIcon} alt="Whatsapp Icon" />
            </a>
        </>
    );
};

export default Whatsapp;
