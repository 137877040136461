import './App.css';
import { useEffect } from 'react';



import 'bootstrap/dist/css/bootstrap.min.css';

import 'aos/dist/aos.css';
import AOS from 'aos';
import Main from './components/main/Main';
import Sobre from './components/sobre/Sobre';
import Contato from './components/contato/Contato';
import Atuacao from './components/atuacao/Atuacao';
import Terapia from './components/terapia/Terapia';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Atendimento from './components/atendimento/atendimento';
import SobreIcones from './components/sobreIcones/SobreIcones';
import Whatsapp from './components/whatsapp/Whatsapp';
import Postagem from './components/postagem/Postagem';



function App() {
  useEffect(() => {
    AOS.init({disable: 'mobile'});  
  }, []);
  return (
    <div>
      <Header />
      <Main />
      <Sobre />
      <SobreIcones/>
      <Terapia />
      <Atuacao />
      <Whatsapp/>
      <Contato />
      <Atendimento />
      <Postagem/>
      <Footer />
    </div>
  );
}

export default App;
