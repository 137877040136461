import React from 'react';
import './SobreIcones.css';
import genetica from '../../assets/genetica.webp';
import dna from '../../assets/dna.webp';
import microscopio from '../../assets/microscopio.webp';

const SobreIcones = () => {
    return (
        <section className="sobre-icones">
            <h2 className="titulo">Atuação</h2>
            <div className="icones-container">
               
                {/* <div className="icone" data-aos="fade-right"  data-aos-easing="linear"  data-aos-offset="-500" data-aos-duration="2000" data-aos-anchor-placement="center-right">
                    <div className="icone-img">
                        <img src={dna} alt="Imagem 2" />
                    </div>
                    <p>Análise de Predisposições</p>
                </div> */}
                <div className="icone" data-aos="fade-right"  data-aos-easing="linear"  data-aos-offset="-500" data-aos-duration="2000" data-aos-anchor-placement="center-right">
                    <div className="icone-img">
                        <img src={genetica} alt="Imagem 1" />
                    </div>
                    <p>Acompanhamento</p>
                </div>
                {/* <div className="icone" data-aos="fade-down"  data-aos-easing="linear"  data-aos-offset="-500" data-aos-duration="2000">
                    <div className="icone-img">
                        <img src={genetica} alt="Imagem 1" />
                    </div>
                    <p>Acompanhamento</p>
                </div> */}
                <div className="icone" data-aos="fade-left"  data-aos-easing="linear"  data-aos-offset="-500" data-aos-duration="2000" data-aos-anchor-placement="center-left">
                    <div className="icone-img">
                        <img src={microscopio} alt="Imagem 3" />
                    </div>
                    <p>Investigação de Causas</p>
                </div>
            </div>
        </section>
    );
}

export default SobreIcones;
