import React from 'react';
import './Sobre.css';
import retrato from '../../assets/retrato.webp';

const Sobre = () => {
    return (
        <section className="sobre-section" id="sobre">
            <div className="container-sobre">
                <h1 className="section-title">Conheça o Dr. Daltro Castelar</h1>
                <div className="texto">
                    <h2>Médico Geneticista</h2>
                    <h3>CRM: RJ 1133063 | RQE: 39469</h3>
                    <hr className="line-style" />
                    <p>
                    Médico geneticista dedicado ao estudo e à prática da genética médica com as melhores evidências científicas disponíveis. Meu trabalho é focado em ajudar pacientes e suas famílias a compreender e lidar com condições genéticas complexas. Sou médico formado pela Universidade Federal de Santa Catarina e geneticista pelo Instituto Fernandes Figueira, ligado à Fiocruz. 
                    </p>
                </div>
                <div className="foto" data-aos="fade-left"  data-aos-easing="linear"  data-aos-offset="-500" data-aos-duration="2000"> 
                    <img src={retrato} alt="Retrato de Daltro" />
                </div>
            </div>
        </section>
    );
}

export default Sobre;
