import React, { useEffect, useState } from "react";
import Background from "../../assets/main-background.webp";
import "./Main.css";

const Main = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            requestAnimationFrame(() => {
                setScrollY(window.scrollY);
            });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <section className="main-container" id="home">
            <div className="overlay">
                <div data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="3000">
                    <h1>Daltro Castelar</h1>
                    <h2>Médico Geneticista</h2>
                </div>
            </div>
            <div className="background-wrapper">
                <img
                    src={Background}
                    alt="Background com padrão abstrato"
                    className="background-image"
                    style={{ transform: `translateY(${Math.min(scrollY * 0.6, 165)}px)` }}
                />
            </div>
        </section>
    );
};

export default Main;
