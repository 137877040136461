import React from 'react';
import './Atuacao.css';


const Atuacao = () => {
    return (
        <section id="atuacao">
        <div className="container texto">
            <h2>Genética Médica</h2>
            <h3>Quem é o médico geneticista?</h3>
            <span>Um médico geneticista é um especialista qualificado na área da genética médica, dedicado ao diagnóstico de doenças causadas por mecanismos genéticos.
                Com expertise em aconselhamento genético e interpretação de testes genéticos, eles colaboram com pacientes e famílias para oferecer suporte emocional e informação crucial sobre riscos genéticos. Além disso, contribuem ativamente para avanços na pesquisa clínica e educação médica, promovendo a compreensão e o manejo eficaz de condições genéticas complexas.<br/><br/><br/>
            
            <h3>Quais são as áreas de atuação na Genética Médica?</h3>

            <span>
              O médico geneticista tem múltiplas áreas de atuação. Dentre elas:
            </span>

            <ul>
                <li> Oncogenética </li>
                <li> Transtorno do Espectro do Autismo </li>
                <li> Deficiência Intelectual </li>
                <li> Erros inatos da imunidade </li>
                <li> Erros inatos do metabolismo </li>
                <li> Malformações congênitas </li>
                <li> Perdas gestacionais e infertilidade </li>
            </ul>
            </span>
        </div>
        </section>
    );
}

export default Atuacao;