import React from 'react';
import './Postagem.css';

const Postagem = () => {
  return (
    <section id="postagem">
      <div className="container texto">
        <h2>Síndrome de Williams</h2>
        <p>
          A Síndrome de Williams (SW), também conhecida como síndrome de Williams-Beuren, é uma condição genética rara, causada por uma deleção de aproximadamente 26 a 28 genes no cromossomo 7, especificamente na região 7q11.23. O gene mais importante nesta deleção é o gene da elastina (ELN), cuja ausência está associada a muitos dos problemas vasculares e esqueléticos observados na síndrome. A incidência estimada da SW é de 1 em cada 7.500 a 10.000 nascimentos, sendo uma doença presente em diferentes etnias e ambos os sexos.
        </p>

        <h3>Características Faciais e Físicas</h3>
        <p>
          Indivíduos com SW geralmente apresentam características faciais atípicas, que incluem:
        </p>
        <ul>
          <li>Testa ampla;</li>
          <li>Nariz pequeno com ponta bulbosa;</li>
          <li>Lábios espessos e largos;</li>
          <li>Íris com um padrão estrelado.</li>
        </ul>
        <p>
          Essas características podem se tornar mais evidentes com o tempo. Além disso, essas crianças costumam apresentar baixa estatura e problemas de mobilidade devido à hipermobilidade articular na infância e rigidez progressiva na idade adulta.
        </p>

        <h3>Desenvolvimento Cognitivo e Comportamental</h3>
        <p>
          Cognitivamente, as pessoas com SW apresentam deficiência intelectual variável, com um perfil cognitivo único. Embora possam ter bom desenvolvimento linguístico e habilidades sociais, frequentemente apresentam dificuldades severas em habilidades visuo-espaciais, como desenhar ou montar quebra-cabeças. Curiosamente, apresentam uma sociabilidade extrema, com personalidade amigável e empática, o que às vezes resulta em falta de noção de perigos sociais. No entanto, são propensos a ansiedade, fobias e transtornos de déficit de atenção.
        </p>

        <h3>Complicações Médicas</h3>
        <p>
          Um dos aspectos mais preocupantes da SW são as complicações cardiovasculares. A principal anomalia cardíaca congênita é a estenose aórtica supravalvar, causada pela deficiência de elastina nas paredes dos vasos sanguíneos, levando a uma rigidez arterial.
        </p>
        <ul>
          <li>Hipertensão arterial;</li>
          <li>Estenose das artérias renais;</li>
          <li>Problemas gastrointestinais, como refluxo gastroesofágico;</li>
          <li>Distúrbios ortopédicos (como displasia de quadril);</li>
          <li>Problemas de tireoide, com risco aumentado para hipotireoidismo;</li>
          <li>Surdez;</li>
          <li>Problemas visuais.</li>
        </ul>

        <h3>Diagnóstico e Tratamento</h3>
        <p>
          O diagnóstico da síndrome de Williams é feito inicialmente com uma suspeita clínica dependendo dos sinais e sintomas do paciente. Posteriormente, pode ser confirmado através de testes genéticos, como a técnica de hibridização fluorescente in situ (FISH), que pode identificar a deleção na região 7q11.23. Atualmente, o exame mais utilizado é o CGH-microarray (microarranjo cromossômico), que também evidenciará a perda de material genético na região da Síndrome de Williams.
        </p>

        <h3>Prognóstico</h3>
        <p>
          Apesar das possíveis complicações médicas, muitas pessoas com SW podem ter uma boa qualidade de vida se acompanhadas de forma adequada desde a infância. A expectativa de vida pode ser levemente reduzida devido às complicações cardíacas, mas, com monitoramento e intervenções apropriadas, é possível controlar muitas das manifestações.
        </p>
      </div>
    </section>
  );
};

export default Postagem;
