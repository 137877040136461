import React from 'react';
import './Terapia.css';
import dna from '../../assets/DNA2.webp';


const Terapia = () => {
  return (
    <section id="terapia">
      <div className="container">
        <h2>Aconselhamento Genético</h2>
        <div className="left foto" data-aos="fade-right" data-aos-easing="linear" data-aos-offset="-500" data-aos-duration="2000">
          <img src={dna} />
        </div>
        <div className='texto' data-aos="fade-left" data-aos-easing="linear" data-aos-offset="-500" data-aos-duration="2000">
          <h3>O que é aconselhamento genético? </h3>
            <p>
              Aconselhamento pode ser definido, de forma resumida, como o processo de ajudar as pessoas a compreender e se adaptar às implicações médicas, psicológicas e familiares decorrentes da contribuição genética para uma determinada doença ou condição.
            </p>
            <p>
              Em um sentido mais amplo o aconselhamento engloba o processo de entender as causas de uma doença genética, bem como seu funcionamento, progressão e acompanhamento.
            </p>
            <p>
              Além disso, envolve também entender a hereditariedade ou o risco de novos casos ocorrerem na família ou em outras gerações.
            </p>
        </div>
      </div>
    </section>
  );
}

export default Terapia;