import React from 'react';
import './atendimento.css';
import Botafogo from "../../assets/botafogo.webp";
import Barra from "../../assets/barra.webp";
import Nit from "../../assets/nt.webp"
import Virtual  from "../../assets/virtual.webp"

const Atendimento = () => {
    return (
        <section className='atendimento' id='atendimento'>
            <div className='cards'>
                <div className='articles'>
                    <div className="atendimento-titulo">
                        <h2>Atendimento</h2>
                    </div>
                    <div className='articles-row'>
                        <div className='card'>
                            <div className='img-container'>
                                <img src={Virtual} alt='Virtual' />
                            </div>
                            <h1>Teleconsultas</h1>
                            <p>Atendimento virtual e personalizado, para um maior conforto</p>
                            <p>📲(21) 99329-8991 (atendimento particular)</p>
                        </div>
                        <div className='card'>
                            <div className='img-container'>
                                <img src={Nit} alt='Niteroi' />
                            </div>
                            <h1>Niterói</h1>
                            <p>Niterói – Rua Lopes trovão 318, Icaraí – Sala 601</p>
                            <p>📲 (21) 99532-6778 (atendimento presencial)</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Atendimento;
